<template>
  <div class="bg-box">
    <img style="width: 100%" src="../../assets/img/banner.png" alt="" />
    <div class="w" v-if="istext">
      <div class="works-top">
        <div class="title">
          <div></div>
          <div>兼职测试</div>
        </div>
        <div class="test-time">
          <div class="child1">考试时间</div>
          <van-count-down
          ref="countDown"
            class="child2"
            @finish="changefinish()"
            @change="eventschange"
            :time="time"
          />
        </div>
      </div>

      <div class="theme-box">
        <div class="serial-number">
          <div>
            <div class="serial-title">{{ info.title }}</div>
            <div class="number-item">
              <div
                class="number-item-div"
                @click="handlechange(ele.id, i)"
                :class="[
                  { colorbg: id == ele.id },
                  { already: $options.filter.pitch(ele.answer) },
                  { green: ele.color == 1 },
                  { red: ele.color == 2 },
                ]"
                v-for="(ele, i) in info.question"
                :key="i"
              >
                {{ i+1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="theme">
          <div>
            <div class="title-theme">设计师考试</div>
            <div class="no-list" v-show="JSON.stringify(info) == '{}'">
              加载中..
            </div>
            <!-- 第一层 -->
            <div
              class="item-box"
            >
              <div class="b-title">
                {{ info.title }}
              </div>
              <!-- 第二层 -->
              <div
                v-for="(ele, i) in info.question"
                :key="i"
                class="ele-box"
                v-show="ele.id == id"
              >
                <div class="ele-box-item">
                  {{ index+1 + "、" + ele.content }}
                  <span class="multext" v-if="ele.type==2">(多选题)</span>
                </div>
                <!-- 第三层 -->
                <div
                  v-for="(test, ti) in ele.answer"
                  :key="ti"
                  class="test-iconcheck"
                  @click="radioChange(ele.type, test.answer_id, index, i, ti)"
                >
                  <div>
                    <i v-show="test.type" class="el-icon-check"></i>
                  </div>
                  <span>{{ test.content }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="bottom-font">请选择你认为正确的答案！</div>
            <div class="bottom-btn">
              <div @click="switchover(1)">上一题</div>
              <div @click="switchover(2)">下一题</div>
            </div>
            <div class="row-xian"></div>
            <div class="btn-commit">
              <div @click="handlecommit()">{{subtext}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 失败 -->
    <div class="popup">
      <el-dialog
        :show-close="false"
        :visible.sync="indexDialogVisible"
        width="500px"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="img-box-popup">
          <div class="del-close">
            <i class="el-icon-close" @click="onClose"></i>
          </div>
          <img
            style="width: 150px; height: 123px"
            src="../../assets/img/icon-2x.png"
            alt=""
          />
          <div class="close-font">{{ btn_font }}</div>
          <div class="index-btn" @click="handleindex">{{ btntext }}</div>
        </div>
      </el-dialog>
    </div>
    <!-- 通过测试 -->
    <div class="popup">
      <el-dialog
        :show-close="false"
        :visible.sync="yesDialogVisible"
        width="500px"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="img-box-popup">
          <div class="del-close">
            <i class="el-icon-close" @click="yesDialogVisible = false"></i>
          </div>
          <img
            style="width: 166px; height: 123px"
            src="../../assets/img/works.png"
            alt=""
          />
          <div class="close-font" style="color: #fe8220">
            恭喜测试通过，请进入实操测试！
          </div>
          <div class="index-btn" @click="handleActualOperation">
            进入实操测试
          </div>
        </div>
      </el-dialog>
    </div>
    <div style="height: 300px"></div>
  </div>
</template>

<script>
import { setStore, getStore, removeStore } from "../../assets/js/utils";
import { get_test_topic, submit_test } from "../../assets/api/index";
export default {
  components: {},
  data() {
    return {
      i: 0,
      index: 0,
      multiple_id: "",
      id: "",
      test_client_id: "", // 进入实操练习 所需id
      info: {},
      btn_font: "",
      indexDialogVisible: false,
      yesDialogVisible: false,
      time: 30 * 60000,
      btntext: "",
      subtext:'提交',
      istext:true,
      sign:'',
      answer:[]
    };
  },
  async mounted() {
    await this.getTestTopic();
    if(this.info){
      this.id = this.info.question[0].id;
      this.multiple_id = this.info.id;
    }else{
      this.id=""
      this.$message.warning('暂无试题')
      this.istext = false
    }
    
    
  },
  filter: {
    pitch(val) {
      var is_ok = false;
      for (let index = 0; index < val.length; index++) {
        if (val[index].type) {
          is_ok = true;
          break;
        } else {
          is_ok = false;
        }
      }

      return is_ok;
    },
  },
  computed: {
    // 选中的数据
    eventLists() {
      console.log(111);
      let list = [];
        this.info.question.forEach((ele) => {
          let type = ele.answer.filter((test) => {
            if (test.type) {
              return test;
            }
          });
          if (type.length > 0) {
            let id = type.map((item) => {
              return item.answer_id;
            });
            list.push({
              question: ele.id,
              answer: id.join(","),
            });
          }
        });

      return list;
    },
  },
  methods: {
    onClose(){
      this.indexDialogVisible = false
      if(this.btntext == "重新测试"){
          this.subtext='重新测试'
      }
      
   
    },
    // 判断对错颜色
    is_color() {
      this.info.question.forEach((ele) => {
        this.answer.forEach((obj)=>{
          if(ele.id == obj.question){
            if(obj.result.includes('0')){
              ele.color = 2;
            }else{
              ele.color = 1;
            }
          }
        })
      });
    },
    switchover(num) {
      if (num == 1) {
        // 上一题
        if (this.index > 0) {
          this.index--;
          this.id = this.info.question[this.index].id;
        }
      } else {
        // 下一题
        if (this.index < this.info.question.length - 1) {
          this.index++;
          this.id = this.info.question[this.index].id;
        }
      }
    },
    handlechange(id, i) {
      this.id = id;
      this.index = i;
    },
    // 返回首页
    handleindex() {
      if (this.btntext == "返回首页") {
        this.$router.replace({ path: "/" });
      } else if (this.btntext == "重新测试") {
        this.subtext='提交'
        this.$refs.countDown.reset();
        this.getTestTopic();
        this.indexDialogVisible = false;
        this.yesDialogVisible = false;
        this.$router.go(0)
      }
    },
    async submitTest() {
      if(this.info.question.length>this.eventLists.length){
        this.$message.warning('请完成全部试题')
        return
      }
      let params = {
        test_id: this.$route.params.id,
        sign:this.sign,
        answer: JSON.stringify(this.eventLists),
      };
      let res = await submit_test(params);
      if (res.code==200) {
        // 0已超过两次 1通过 2未通过
        this.answer = res.data.answer
        this.is_color()
        if (res.data.test_pass == 1) {
          this.test_client_id = res.data.test_record_id;
          this.yesDialogVisible = true;
          this.btntext = "通过";
        } else {
          this.btn_font = res.msg;
          if (res.data.surplus == 0) {
            this.btntext = "返回首页";
          } else {
            this.btntext = "重新测试";
          }
          this.indexDialogVisible = true;
        }
      }else{
         this.$message.warning(res.msg)
      }
    },
    // 进入实操测试
    handleActualOperation() {
      this.$router.push({ path: `/actual-operation/${this.test_client_id}` });
    },
    // 提交
    handlecommit() {
      if (this.subtext == "重新测试") {
        this.$refs.countDown.reset();
        this.getTestTopic();
        this.indexDialogVisible = false;
        this.yesDialogVisible = false;
        this.subtext='提交'
        this.$router.go(0)
      }else{
        // this.is_color(); // 显示颜色
        this.submitTest();
      }

    },
    // 选择操作
    radioChange(type, id, index, i, ti) {
      if (type == 1) {
        // 单选
        this.info.question[i].answer.forEach(
          (item) => {
            if (item.answer_id== id) {
              this.$set(item, "type", true);
            } else {
              this.$set(item, "type", false);
            }
          }
        );

      } else {
        // 多选

        this.info.question[i].answer[ti].type = !this
          .info.question[i].answer[ti].type;
      }
    },
    // 获取详情
    async getTestTopic() {
      let params = {
        test_id: this.$route.params.id,
        sign:this.$route.query.sign
      };
      let res = await get_test_topic(params);
      if (res.code == 200) {
        this.time = Number(res.data.answer_duration) * 60000;
          res.data.question.forEach((ele) => {
            ele.color = "";
            ele.answer.forEach((test) => {
              test.type = false;
            });
          })
          this.info = res.data;
          this.sign = res.data.sign
          console.log(222,this.info);
        
      }
    },
    changefinish() {
      // removeStore("time");
      // console.log("时间到");
      this.$message.error('考试时间到')
      this.$router.push({path: '/'})
    },
    eventschange(a) {
      // let time = this.date(a.hours, a.minutes, a.seconds);
      // setStore("time", time);
    },
    date(h, m, s) {
      let timeH = h > 0 ? h * 3600 : 0;
      let timeM = m > 0 ? m * 60 : 0;
      let timeS = s > 0 ? s : 0;
      return (timeH + timeM + timeS) * 1000;
    },
  },
};
</script>

<style scoped>
.multext{
  font-size: 14px;
  color: #e60012;
  margin-left: 10px;
}
.green {
  background-color: #009944 !important;
  color: #fff !important;
}
.red {
  background-color: #e60012 !important;
  color: #fff !important;
}
.row-xian {
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
  margin-bottom: 30px;
}
.bottom-btn {
  display: flex;
  margin-top: 20px;
  margin-bottom: 60px;
}
.bottom-btn div {
  cursor: pointer;
  background-color: #fbad51;
  font-size: 18px;
  color: #fff;
  width: 124px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  margin-right: 30px;
}
.bottom-font {
  font-size: 13px;
  color: #999999;
}
.number-item {
  display: flex;
  flex-wrap: wrap;
}
.number-item-div {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #dcdcdc;
  color: #333333;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
}
.colorbg {
  /* color: #fff;
  background-color: #fbad51; */
  border: 2px solid #fbad51;
}
.already {
  background-color: #d2d2d2;
  color: #000;
}
.serial-title {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.btn-commit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-commit div {
  cursor: pointer;
  width: 220px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  background-color: #fe8424;
  font-size: 20px;
  border-radius: 25px;
}
.item-box .b-title {
  margin: 25px 0;
  font-weight: 700;
  font-size: 22px;
}
.ele-box {
  margin: 30px;
  height: 330px;
}
.ele-box-item {
  font-size: 20px;
  color: #000;
  margin-bottom: 15px;
  line-height: 28px;
}
.test-iconcheck {
  display: flex;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
  font-size: 16px;
}
.test-iconcheck div {
  border: 1px solid #000;
  margin-left: 20px;
  margin-right: 10px;
  width: 16px;
  font-size: 16px;
  color: #000;
  height: 16px;
  border-radius: 5px;
}
.title-theme {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 26px;
}
.theme {
  width: 73.5%;
  background-color: #fff;
  margin-top: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 40px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.test-time {
  display: flex;
  width: 240px;
  height: 30px;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #f26800;
  background-color: #fff;
  overflow: hidden;
}
.test-time .child1 {
  background-color: #f26800;
  color: #fff;
  height: 30px;
  text-align: center;
  line-height: 30px;
  width: 120px;
  border-radius: 15px;
}
.test-time .child2 {
  width: 120px;
  text-align: center;
  color: #f26800;
  font-weight: 700;
}
.works-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.title {
  display: flex;
  align-items: center;
}
.title div:nth-child(1) {
  width: 8px;
  height: 24px;
  background-color: #fbad51;
}
.title div:nth-child(2) {
  font-size: 20px;
  color: #000;
  margin-left: 10px;
  font-weight: 600;
}
.theme-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.serial-number {
  width: 25%;
  background-color: #fff;
  margin-top: 30px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0px 20px 20px;
  box-sizing: border-box;
}
</style>